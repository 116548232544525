.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

.mr-t0 {
  margin-top: 0;
}

.mr-t5 {
  margin-top: 5;
}

.mr-t10 {
  margin-top: 10;
}

.mr-b30 {
  margin-bottom: 30px;
}

.tr-lable.mr-b8 {
  margin-bottom: 8px !important;
}

.ml-l10 {
  margin-left: 10px;
}

.ml-l20 {
  margin-left: 20px;
}

.mw-100 {
  max-width: 100%;
}

.ml-auto {
  margin-left: auto;

}

.mr-b20 {
  margin-bottom: 20px;
}

.mr-t20 {
  margin-top: 20px;
}

.mw-100 {
  max-width: 100%;
}

.mr-r5 {
  margin-right: 5px;
}

.mr-t30 {
  margin-top: 30px;
}

.w-39 {
  width: 39%;
}

.w-40 {
  width: 40%;
}

.mr-b0 {
  margin-bottom: 0;
}

.d-save-btn {
  display: none;
}

.blue-ltr {
  color: #458fff !important;
  text-align: center;
}

.green-ltr {
  color: #46a96f !important;
  text-align: center;
}

.red-ltr {
  color: #fff !important;
  text-align: center;
}

.brown-ltr {
  color: #cb874e !important;
  text-align: center;
}

.valid-dd-toggle::after {
  opacity: 0;
}

.valid-dd-toggle {
  background-color: transparent;
  border: 0;
}

.pd-20 {
  padding: 20px;
}

.collpase-ic {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 25px;
  margin: 10px;
}

.delet-form {
  max-width: 35px;
  margin-left: auto;
  top: 45px;
  border: 0;
  padding: 3px;
  position: relative;
  right: 25px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 4px 0px;
}

.mr-9 {
  margin: 9px;
}

.collpase-ic-new {
  border-top: 0.5px solid #e0e0e0;
  margin-top: 20px;
}

.collpase-ic-new button {
  background-color: transparent;
  border: 0;
  color: #2dc3e8;
  font-family: "OpenSans-SemiBold.ttf";
  padding: 15px;
}

.sb-blue {
  background: #f5fdff;
  border-radius: 8px;
}

.subcontent.text-center button {
  background-color: transparent;
  border: 0;
  color: #2dc3e8;
  font-family: "OpenSans-SemiBold.ttf";
}

.inner-block-appdet {
  background: #f5fdff;
  border-radius: 8px;
  padding: 16px 25px;
}

.red-inc-slect {
  /* background: #fff8f8 !important; */
  border: 1px solid rgba(255, 133, 133, 0.37) !important;
  border-radius: 4px !important;
}

.doller-ip {
  position: relative;
  padding: 10px 25px !important;
}

.dollar-sign {
  position: absolute;
  left: 10px;
  top: 60%;
  z-index: 1;
}

.cust-badge {
  color: #fff;
  background: #01db3e;
  border-radius: 2px;
  font-size: 6px;
  position: absolute;
  left: 13px;
  z-index: 1;
}

.breadcrumb {
  margin-bottom: 0;
}

.modal-bd-f {
  font-size: 14px;
  color: #303030;
  font-family: "OpenSans-Regular";
}

.col-white {
  background-color: #fff !important;
}

.bg-grey {
  background: #eeeeee !important;
}

.table> :not(:first-child) {
  border-top: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card {
  border: 0;
}

.custom-breadcrumb li {
  align-items: center;
}

.checkmark {
  cursor: pointer;
}

/* ----------common content starts */
.sidebar.active~.home_content {
  padding-left: 270px;
}

.sidebar.active~.top-content {
  padding-left: 270px;
}

.sidebar.active~.home_content,
.sidebar.active~.top-content {
  /* width: 100%;
  left: 0; */
  width: 100%;
  left: 0px;
}

.sidebar.active~.home_content,
.sidebar.active~.top-content {
  /* z-index: 100; */
}

.home_content {
  /* position: absolute; */
  /* height: 100%;
  width: calc(100% - 78px);
  left: 78px; */
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2px);
  transition: all 0.5s ease;
  padding: 24px 30px;
  /* padding-left: 100px; */
  padding-top: 30px;
}

.top-content {
  /* position: absolute; */
  /* height: 100%; */
  /* width: calc(100% - 78px); */
  /* left: 78px; */
  background: #2dc3e8;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2px);
  transition: all 0.5s ease;
  padding: 1px 30px;
  z-index: 999;
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-top: 10px;
  /* width: calc(100% - 78px); */
}

.dropdown-toggle.topbar-profile::after {
  display: none;
}

.top-h6 h6 {
  font-size: 12px;
  line-height: 7px;
  font-family: "OpenSans-Bold";
  color: #ffffff;
}

.top-h6 span {
  font-size: 10px;
  line-height: 7px;
  font-family: "OpenSans-SemiBold.ttf";
  color: #ffffff;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.top-dd-content li {
  padding: 5px;
}

.top-dd-content li a img {
  padding-right: 15px;
}

.top-dd-content li a {
  font-size: 12px;
  line-height: 16px;
  color: #606060;
  font-family: "OpenSans-Regular";
}

.dropdown-menu.show:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #ffffff 10px;
  top: -10px;
  content: " ";
  height: 0;
  right: 15px;
  margin-left: -13px;
  position: absolute;
  width: 0;
  transform: rotate(180deg);
}

.dd-pro-head img {
  max-width: 40px;
  height: 40px;
  width: 100%;
  object-fit: cover;
  display: flex;
  margin: auto;
}

.dd-pro-head span {
  text-align: center;
  display: flex;
  justify-content: center;
  word-break: break-all;
  font-size: 10px;
  line-height: 14px;
  /* font-family: "OpenSans-Regular"; */
  color: #606060;
}

.custom-dd-menu {
  width: 212px;
  padding: 12px 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.dd-pro-head {
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.dd-pro-head h6 {
  font-size: 18px;
  line-height: 25px;
  color: #303030;
  font-family: "OpenSans-Bold";
}

.bg-grey2 {
  background-color: #f8f8f8;
  border-radius: 0px 8px 8px 8px;
  padding: 15px;
}

/* -------------------------------------- */
.application-head h6 {
  font-size: 18px;
  line-height: 25px;
  color: #303030;
  font-family: "OpenSans-SemiBold.ttf";
}

.application-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.search-block input {
  background-image: url(../images/search.svg);
  background-position: 3% 50%;
  background-repeat: no-repeat;
  padding: 9px 10px 9px 25px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  color: #909090;
  font-family: "OpenSans-SemiBold.ttf";
  text-transform: uppercase;
  color: #909090;
}

.search-block button {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.form-check-input:focus {
  box-shadow: none;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, ">");
}

.head-blue {
  color: #2dc3e8;
  border: 0.5px solid #2dc3e8;
  filter: drop-shadow(4px 8px 20px rgba(45, 195, 232, 0.2));
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
}

.head-white {
  color: #ffffff;
  background: #2dc3e8;
  box-shadow: 4px 8px 20px rgba(45, 195, 232, 0.2);
  border-radius: 4px;
  border: 0;
  padding: 10px;
  font-size: 14px;
  line-height: 19px;
}

.dropdwn-btn {
  background-image: url(../images/dropdown.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
}

.dropdwn-btn.collapsed {
  background-image: url(../images/dropdown.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
  background: #eeeeee;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

li {
  list-style: none;
}

.accordian-dropdown-head h6 {
  font-size: 12px;
  line-height: 14px;
  color: #909090;
}

.dd-list-wrap h4,
.dd-list-wrap h5,
.dd-list-wrap h6 {
  font-size: 14px;
  line-height: 14px;
  font-family: "OpenSans-Regular";
  color: #303030;
}

.dd-list ul li:nth-child(odd) {
  background-color: #f9f9f9;
}

.accordian-dropdown-head,
.dd-list ul li {
  padding: 0px 24px 0px 20px;
}

.accordion-header-custom button {
  color: #606060;
  font-family: "OpenSans-SemiBold.ttf";
  font-size: 14px;
  line-height: 19px;
}

.accordion-button:not(.collapsed) {
  color: #606060;
}

.accordion-header-custom {
  /* border-radius: 8px;
  border: 1px solid green; */
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #eeeeee;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/dropdown-up.svg);
  transform: rotate(0deg);
}

.accordion-button::after {
  background-image: url(../images/dropdown-down.svg);
}

.accordian-dropdown-head {
  background: #eeeeee;
}

.accordion-header-custom button img,
.li-h6 img {
  margin-right: 8px;
}

.dd-list-wrap h4 {
  padding-left: 18px;
  /* padding-bottom: 5px; */
}

.accordion-header-custom button {
  background: #eeeeee;
  border: 1px solid #e0e0e0;
  /* border-radius: 8px; */
}

.accordion-item {
  border: 1px solid red;
  border-radius: 8px;
}

.accordion-item {
  margin-bottom: 12px;
}

.metadat-wrap-inner {
  background-color: #fff;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.dd-list-wrap h4 span {
  font-size: 10px;
  line-height: 14px;
  font-family: "OpenSans-Regular";
  color: #606060;
}

.dd-list-info h6 {
  font-size: 10px;
  line-height: 14px;
  font-family: "OpenSans-Bold";
  color: #606060;
}

.dd-list-main-wrap {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.metadat-wrap-inner h6 {
  font-size: 14px;
  line-height: 19px;
  font-family: "OpenSans-SemiBold.ttf";
  color: #606060;
  margin-bottom: 5px;
}

.accordion-item,
.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-item:last-of-type {
  border: 0;
  border-radius: 0;
}

.accordion-button:focus {
  border: 0;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.summery-table-main tbody tr td input {
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 5px 12px;
}

table.table.summery-table-main thead tr th,
table.table.summery-table-main-one thead tr th {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #909090;
  font-family: "OpenSans-Regular";
}

.summery-table-main-one tbody tr td input {
  width: 90px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 5px 12px;
}

.summery-table-main-two tbody tr td input {
  width: 135px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 5px 12px;
}

.summery-table-btn {
  background-color: #fff !important;
}

.payst-flex button {
  padding: 6px;
}

/* --------------------------Common checkbox------------------ */
/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  /* top: 0;
  left: 0; */
  height: 25px;
  width: 25px;
  background: #ffffff;
  border: 1px solid #e7e5e5;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
  background-color: #2dc3e8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.ch-span {
  font-size: 12px;
  line-height: 16px;
  /* Grey 2 */
  color: #606060;
  font-family: "OpenSans-SemiBold.ttf";
  margin-left: 35px;
}

label.container-checkbox {
  /* top: 25px; */
  position: relative;
  display: flex;
  align-items: center;
}

.src-bl {
  display: flex;
}

.search-block {
  display: flex;
}

.emp-inputs span {
  font-size: 10px;
  color: #ff4848;
}

.header-smb {
  ont-size: 16px;
  line-height: 22px;
  font-family: "OpenSans-SemiBold.ttf";
  color: #606060;
  margin-bottom: 21px;
}

.bg-f {
  background-color: #fff !important;
}

/* ---------------------------------------------------------------- */
/* Cus tabs css start */
.tabs-head .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.tabs-head .nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}

.tabs-head .nav-tabs .nav-link {
  font-family: OpenSans-Regular;
  color: #606060;
  font-size: 12px;
  padding: 15px 50px;
}

.tabs-head .nav-tabs .nav-item.show .nav-link,
.tabs-head .nav-tabs .nav-link.active {
  border-color: #e0e0e0 #e0e0e0 transparent;
  border-radius: 8px 8px 0px 0px;
  background: #f8f8f8;
  color: #303030;
  font-family: OpenSans-Bold;
  position: relative;
}

/* .tabs-head .nav-tabs .nav-link.active::after{
  content: "";
  position: absolute;
  width: 18px;
  height: 17px;
  background: white;
  bottom: -1px;
  right: -18.3px;
  border-bottom-left-radius: 8px;
  box-shadow: -3px 3px 0px #f8f8f8;
  border-left: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  margin-right: 1px;
} */
.cus-tabs-body {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-top: 0px;
  padding: 25px;
}

.cus-child-tabs .nav-tabs .nav-link {
  font-size: 12px;
  color: #909090;
  font-family: OpenSans-Regular;
  border: 0px;
  padding: 14px 12px;
  position: relative;
  overflow: hidden;
}

.cus-child-tabs .nav-tabs .nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #2dc3e8;
  border-radius: 4px 4px 0px 0px;
  bottom: -3px;
  left: 0px;
  transition: all 0.5s linear 0s;
}

.cus-child-tabs .nav-tabs .nav-item.show .nav-link,
.cus-child-tabs .nav-tabs .nav-link.active {
  background: transparent;
  text-align: center;
  color: #2dc3e8;
  font-size: 12px;
  font-family: OpenSans-Bold;
}

.cus-child-tabs .nav-tabs .nav-item.show .nav-link::after,
.cus-child-tabs .nav-tabs .nav-link.active::after {
  transition: all 0.5s linear 0s;
  bottom: 0px;
}

.cus-child-tabs .nav-tabs .nav-link:focus,
.cus-child-tabs .nav-tabs .nav-link:hover {
  border: 0px;
}

.cus-child-tabs .nav-tabs .nav-link:hover::after {
  bottom: 0px;
  transition: all 0.5s linear 0s;
}

.white-card-wrap {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: 20px;
}

.white-card-head {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 20px 16px;
}

.head-title h4 {
  font-size: 18px;
  color: #303030;
  font-family: OpenSans-Bold;
}

.white-card-body {
  padding: 20px 16px;
}

.white-box {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.pd15 {
  padding: 15px;
}

.cus-f-group .form-control {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  font-size: 14px;
  color: #606060;
  font-family: OpenSans-Regular;
  padding: 8px 12px;
  box-sizing: border-box;
}

.cus-f-group input.form-control {
  height: 36px;
}

.cus-f-group select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/select-arrow.png") no-repeat 95% 50%;
  cursor: pointer;
}

.cus-f-group .cus-in-title {
  font-size: 12px;
  color: #606060;
  font-family: OpenSans-SemiBold;
  margin-bottom: 8px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #909090;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #909090;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #909090;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #909090;
}

.form-control:focus {
  box-shadow: none;
}

.a-in-switch {
  width: 162px;
  height: 36px;
  background: #eeeeee;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 2px;
  display: flex;
  position: relative;
  cursor: pointer;
}

.a-in-switch input {
  display: none;
}

.a-in-switch label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.a-in-switch span {
  font-size: 12px;
  color: #606060;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: OpenSans-Regular;
  width: 38px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slidebox {
  width: 120px;
  height: 32px;
  background: #eeeeee;
  border: 0.5px solid #909090;
  box-shadow: 4px 8px 20px rgba(144, 144, 144, 0.2);
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%) translateX(0px);
  transition: all 0.3s linear 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-in-switch input:checked~label .slidebox {
  transform: translateY(-50%) translateX(38px);
  transition: all 0.3s linear 0s;
  background: #ebfff3;
  border: 1px solid #46a96f;
  border-radius: 3px;
}

.slidebox::after {
  content: "Inactive";
  /* position: absolute; */
  color: #606060;
  font-size: 12px;
  font-family: OpenSans-Bold;
}

.a-in-switch input:checked~label .slidebox::after {
  content: "Active";
  color: #46a96f;
}

.slidebox::before {
  content: "";
  display: block;
  width: 14px;
  height: 11px;
  background: url("../images/cross.png") center no-repeat;
  margin-top: -2px;
  margin-right: 8px;
}

.a-in-switch input:checked~label .slidebox::before {
  content: "";
  background: url("../images/g-check.png");
}

.three-o-block h6 {
  font-size: 12px;
  color: #606060;
  font-family: OpenSans-Bold;
  margin-bottom: 8px;
}

.cus-m-option input {
  display: none;
}

.cus-m-option {
  width: 100%;
  height: 36px;
  background: #eeeeee;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 2px;
  display: flex;
  align-items: center;
}

.cus-m-option label {
  height: 32px;
  flex: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  max-width: 34px;
  cursor: pointer;
}

.cus-m-option label span {
  line-height: 0px;
  font-family: OpenSans-Bold;
  display: flex;
  align-items: center;
  font-size: 0px;
}

.cus-m-option label span svg {
  margin: 0px 5px;
}

.cus-m-option input:checked+label {
  flex: 1;
  max-width: 100%;
  border-radius: 3px;
}

.cus-m-option input:checked+label.hide-option {
  background: #fff1f1;
  border: 1px solid #ff4848;
}

.cus-m-option input:checked+label.hide-option span {
  font-size: 12px;
  color: #ff4848;
}

.cus-m-option input:checked+label.hide-option span svg path {
  stroke: #ff4848;
}

.cus-m-option input:checked+label.display-option {
  background: #f8ece2;
  border: 1px solid #cb874e;
}

.cus-m-option input:checked+label.display-option span {
  font-size: 12px;
  color: #cb874e;
}

.cus-m-option input:checked+label.display-option span svg path {
  stroke: #cb874e;
}

.cus-m-option input:checked+label.required-option {
  background: #ebfff3;
  border: 1px solid #46a96f;
}

.cus-m-option input:checked+label.required-option span {
  font-size: 12px;
  color: #46a96f;
}

.cus-m-option input:checked+label.required-option span svg path {
  fill: #46a96f;
}

/* -------------------------------------------------------------- */
/* ----------Common Content Ends */

@media (max-width: 1200px) {
  .sidebar.active~.home_content {
    width: calc(100% - 180px);
    left: 180px;
  }

  .sidebar .links_name {
    font-size: 9px;
  }

  .home_content {
    padding: 15px 20px;
  }
}

@media (max-width: 1140px) {
  .src-bl {
    margin-bottom: 20px;
  }

  .search-block input {
    padding: 9px 10px 9px 35px;
  }

  .search-block {
    display: block;
  }

  .search-block input {
    width: 60%;
  }

  .lg-btns {
    display: flex;
  }

  .lg-btns button {
    width: 50% !important;
  }

  /* .application-head {
    display: block;
  } */
}

@media (max-width: 991px) {
  .search-block input {
    padding: 9px 10px 9px 35px;
    font-size: 8px;
  }

  .search-block button {
    width: 30px;
  }

  .search-block .head-blue {
    font-size: 12px;
    line-height: 5px;
  }
}

@media (max-width: 768px) {
  .breadcrumb-item+.breadcrumb-item::before {
    font-size: 8px;
  }

  .head-blue,
  .head-white {
    font-size: 11px;
    line-height: 9px;
    padding: 10px;
  }

  .ch-span.application-head {
    font-size: 10px;
  }

  .emp-inputs input,
  .emp-inputs select {
    height: 35px;
    padding: 3px;
    min-width: 100%;
  }

  .emp-inputs {
    margin-bottom: 10px;
  }

  .d-save-btn {
    display: block;
  }
}

@media (max-width: 575px) {
  .sidebar.active~.home_content {
    left: 0;
    width: 100%;
  }

  .home_content {
    /* width: calc(100% - 57px);
    left: 55px; */
    /* padding-left: 65px; */
    left: 0;
    width: calc(100% - 0px);
  }

  .application-head h6 {
    margin-bottom: 10px;
  }
}

@media print {

  /* styles here */
  .print-hide {
    display: none !important;
  }

  .print-show {
    display: block !important;
  }
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-t10 {
  padding: 15px 0px !important;
}

.pb-t20 {
  padding: 25px 0px !important;
}

/* new code adding */

.green-alert h5 {
  font-size: 12px;
  font-family: OpenSans-Bold;
}

.green-alert {
  background: #ecfbff;
  padding: 12px;
  display: flex;
  align-items: center;
}

.green-alert h5 {
  font-size: 12px;
  font-family: OpenSans-Bold;
  flex: 1;
}

.red-alert-text {
  color: #ff4848;
}

.alert-close {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  margin-left: 20px;
}

.app-option-btn {
  flex: 1;
}

.app-option-btn label {
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 4px;
}

.app-option-btn label input {
  display: none;
}

.app-option-btn label span {
  display: flex;
  width: auto;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 2px 15px;
  font-size: 14px;
  color: #909090;
  font-family: OpenSans-Regular;
}

.app-option-btn label input:checked~span {
  border: 1px solid #2dc3e8;
  background: #ecfbff;
  color: #2dc3e8;
  font-family: OpenSans-Bold;
}

.g-line-btn {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.blue-btn {
  background: #2dc3e8;
  border: 1px solid #2dc3e8;
  box-shadow: 4px 8px 20px rgba(45, 195, 232, 0.2);
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  font-family: OpenSans-Bold;
}

.cus-search input {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  font-family: OpenSans-SemiBold;
  height: 40px;
  box-sizing: border-box;
  padding: 5px 20px;
  min-width: 240px;
}

.cus-search input::-webkit-input-placeholder {
  color: #909090;
}

.cus-search input:-ms-input-placeholder {
  color: #909090;
}

.cus-search input::placeholder {
  color: #909090;
}

.app-head-row {
  display: flex;
  align-items: center;
  width: 100R;
}

.a-top-right-block,
.group-btn {
  display: flex;
  align-items: center;
  /* flex: 1; */
}

.app-head-row {
  display: flex;
  align-items: center;
}

.group-btn button {
  margin: 0px 0px 0px 10px;
}

.revenue-box {
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.revenue-box h4 {
  font-size: 24px;
  color: #303030;
  font-family: OpenSans-Bold;
  font-weight: 700;
}

.revenue-box small {
  font-size: 12px;
  color: #909090;
  font-family: OpenSans-Regular;
}

.n-app-graph {
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  min-height: 160px;
  box-sizing: border-box;
  padding: 15px 20px;
}

.n-app-graph h5 {
  font-size: 12px;
  color: #909090;
  font-family: OpenSans-Regular;
}

.t-count h5 {
  font-size: 24px;
  color: #606060;
  font-family: OpenSans-Bold;
  display: flex;
  align-items: center;
}

.t-count h5 small {
  font-size: 12px;
  color: #606060;
  font-family: OpenSans-SemiBold;
  margin-left: 10px;
}

.app-count-table {
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.app-count-table table {
  width: 100%;
}

.app-count-table thead tr th {
  padding: 5px 20px;
}

.cus-switch-btn span {
  display: block;
  background: #eeeeee;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  width: 48px;
  height: 26px;
  position: relative;
  cursor: pointer;
}

.cus-switch-btn input {
  display: none;
}

.cus-switch-btn span::after {
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #f9f9f9;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(1px);
  transition: all 0.3s linear 0s;
}

.cus-switch-btn input:checked~span {
  border: 1px solid #2dc3e8;
  background: #2dc3e833;
}

.cus-switch-btn input:checked~span::after {
  transform: translateY(-50%) translateX(23px);
  background: #2dc3e8;
  transition: all 0.3s linear 0s;
}

.qv-block {
  line-height: 0;
}

.qv-block small {
  font-size: 12px;
  color: #909090;
  font-family: OpenSans-Regular;
  font-weight: normal;
}

.qv-block h4 {
  font-size: 12px;
  color: #2dc3e8;
  font-family: OpenSans-Bold;
}

.qv-block {
  display: flex;
  align-items: center;
}

.ta-no-block h4 {
  font-size: 24px;
  /* color: #606060; */
  font-family: OpenSans-Bold;
}

.ta-no-block small {
  font-size: 12px;
  /* color: #606060; */
  font-family: OpenSans-Regular;
}

.app-count-table table tbody tr td {
  text-align: center;
  border: 1px solid #000000;
  padding: 10px;
}

.ta-no-block {
  line-height: 17px;
}

.app-count-table table tbody tr td.gray-td {
  background: #e9e9e9;
  border: 1px solid #d3d3d3;
}

.app-count-table table tbody tr td.light-sb-td {
  background: #ddf8ff;
  border: 1px solid #add0d9;
}

.app-count-table table tbody tr td.dark-sb-td {
  background: #b0efff;
  border: 1px solid #b9d5ff;
}

.app-count-table table tbody tr td.light-green-td {
  background: #e6fdf0;
  border: 1px solid #bee8d0;
}

.app-count-table table tbody tr td.light-orange-td {
  background: #fff8e2;
  border: 1px solid #ffda6a;
}

.app-count-table table tbody tr td.light-red-td {
  background: #ffeeee;
  border: 1px solid #fcc9c9;
}

.color-blue {
  color: #458fff;
}

.color-d-blue {
  color: #127790;
}

.color-green {
  color: #46a96f;
}

.color-gray {
  color: #606060;
}

.color-orange {
  color: #191970;
}

.color-red {
  color: #ff4848;
}

/* style for Application Details */

.tooltip ul li:hover {
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
  background: #ecfbff;
}

.app_detail .content_wrapper h6 {
  font-family: "OpenSans-Regular";
  font-weight: normal;
  color: #909090;
  font-size: 10px;
  line-height: 14px;
}

.app_detail .content_wrapper p {
  font-family: "OpenSans-Regular";
  font-weight: normal;
  color: #303030;
  font-size: 12px;
  line-height: 16px;
}

/* style for radio type in add application */
.cus-radio {
  font-size: 12px;
  color: #606060;
  font-family: "OpenSans-Regular";
}

/* style for blue bacground box */
.a-blue-box {
  background: #f5fdff;
  border-radius: 8px;
  padding: 15px;
}

.p-d-f-wrap {
  padding-bottom: 20px;
}

/* style for a t and c */
.cus-check-new input {
  display: none;
}

.cus-check-new label {
  position: relative;
  color: #606060;
  font-size: 13px;
  font-family: OpenSans-SemiBold;
  cursor: pointer;
  width: auto;
  padding-left: 25px;
}

.cus-check-new label span {
  display: block;
  background: url("../images/gray-c-box.png") no-repeat center;
  width: 18px;
  height: 18px;
  flex: 1 1 18px;
  max-width: 18px;
  position: absolute;
  left: 0;
  top: 1px;
}

.cus-check-new.red-check label span {
  display: block;
  background: url("../images/red-c-box.png") no-repeat center;
  width: 18px;
  height: 18px;
}

.cus-check-new input:checked~label span {
  background: url("../images/a-c-box.png") no-repeat center;
}

.red {
  color: #ff8585;
}

.app-blue-color {
  color: #22abd7;
}

.info-toggle.disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.info-toggle.disabled b {
  opacity: 0.38;
}

.mr-t60 {
  margin-top: 60px;
}

.type-btn {
  width: 160px;
  height: 36px;
  border: 1px solid #2dc3e8 !important;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  font-size: 14px;
  color: #2dc3e8;
  font-family: OpenSans-SemiBold;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
  justify-content: center;
}

.modal-dialog-centered .modal-content {
  padding: 20px;
}

.mr-b15 {
  margin-bottom: 15px;
}

.mr-b50 {
  margin-bottom: 50px;
}

.stripe-btn {
  border: 1px solid rgba(255, 133, 133, 0.37);
  border-radius: 4px;
  height: 36px;
}

/* style for  forms radio text size */
.i-a-block h5 {
  font-size: 12px;
  color: #606060;
  font-family: OpenSans-SemiBold;
  margin-bottom: 6px;
}

/* style for a circle a number in tabs */
.s-no-index-number,
.s-no-index {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  line-height: 0;
  border: 1px solid black;
  font-size: 13px;
}

/* style for a textarea font size match */
.emp-inputs textarea {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  /* max-width: 320px; */
  width: 100%;
  font-family: "OpenSans-Regular";
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 19px;
  color: #303030;
  padding: 8px;
  margin-bottom: 0;
}

/* style for applicant type in inbox */
.applicant_type {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #909090;
}

/* Added css for set percentage values */
.ta-no-block {
  position: relative;
}

.ta-no-block .per_count {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: end;
  color: #606060;
  position: absolute;
  right: 0;
  top: 0;
}

/* style for total subscribers admin */
.total_subscribers_box {
  min-height: 180px;
  display: flex;
}

.color_light_gray {
  color: #909090;
}

/* style for applicant deatil remove border for active card */
.tab-content .tab-pane:first-child.active.name1-wrap {
  border-top-left-radius: 0;
}

/* style for applicant detail box */
.details-card-row {
  border-radius: 8px;
}

/* react date picker arrow style */
.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years {
  border: 0.45rem solid transparent;
  height: 15px;
  width: 10px;
}

/* style for admin count Badge */
.count_badge {
  width: 21px;
  height: 21px;
  background: #f9bb04;
  border-radius: 12px;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  color: #ffffff;
  margin-left: 6px;
}

/* for input font size */
.emp-inputs input,
.emp-inputs select,
.emp-inputs textarea {
  font-size: 14px !important;
}

.form-label {
  margin-bottom: 6px !important;
}

.details-card-row .static-d-info {
  padding: 0px 5px;
}

/* ============== FILE UPLOAD PREVIEW====================== */
.upload-preview {
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  /* height: 400px;
  width: 350px;

  overflow: auto; */
  /* height: 00px; */
  /* width: 350px; */
}

.upload-preview span {
  font-size: 14px;
}

.upload-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.upload-preview .upload-preview-body {
  height: 450px;
  width: 100%;
  overflow: auto;
}

.upload-preview .upload-preview-body img {
  height: 300px;
  width: 350px;
  /* overflow: auto; */
}

.upload-action {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/* login css strat */
.login-wrap {
  background: url("../images/login_bg.jpg") 98% 50%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0px;
  flex-direction: column;
}

.login-cell {
  flex: 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-block {
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 20px 20px;
}

.f-link a {
  font-size: 11px;
  color: #0c2d48;
  text-align: right;
  /* font-family: Montserrat-Regular; */
  font-family: "OpenSans-Regular";
}

.l-btn {
  /* background: #0c2d48; */
  background: #2DC3E8;
  border-radius: 4px;
  height: 40px;
  border: 1px solid #2DC3E8;
  /* width: 100%; */
  width: 102px;
  color: #ffffff;
  font-size: 14px;
  margin-top: 1rem;
  /* font-family: Montserrat-Regular; */
  font-family: "OpenSans-Regular";
  text-align: center;
  line-height: 19px;
}
.l-btn-forgetPsd
{
  padding: 0 20px;
  height: 40px;
  background-color: #4FB1DC;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  color: #FFF;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid #2DC3E8;
  width: 300px;
}
.l-foot-links {
  width: 100%;
}

.l-foot-links ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;
  margin: 0 auto;
}

.l-foot-links ul li {
  list-style-type: none;
  font-size: 14px;
  line-height: 19px;
  color: #909090;
}

.l-foot-links ul li a {
  font-size: 14px;
  /* color: rgba(255, 255, 255, 0.6); */
  /* font-family: Montserrat-Regular; */
  font-family: "OpenSans-Regular";
  color: #2DC3E8;
  text-decoration: none;
  line-height: 19px;
}

.l-foot-links p {
  margin: 0px;
  text-align: center;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);
  margin: 20px 0px;
  /* font-family: Montserrat-Regular; */
  font-family: "OpenSans-Regular";
}

/* login css end */

/* profile popup css */
.profile_menuItem {
  font-family: 'OpenSans-Regular' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #606060 !important;
}

/* profile popup css end */

/* .app-head-row{
  margin-top: 84px;
} */

/*  Proof of Employment Income bullet hide start*/
.login-radio-types .bullet-style input[type="radio"]+label:before {
  content: none;
}

/*  Proof of Employment Income bullet hide end*/

.revenue-info-row {
  padding: 0px 5px;
}
/* 
@media (max-width: 768px) {
  .n-app-graph {
    margin-top: 20px;
  }

  .ta-no-block .per_count {
    font-size: 7px;
    line-height: 13px;
    right: -4px;
    top: -4px;
  }

  thead.MuiTableHead-root {
    display: none;
  }

  .recharts-wrapper {
    width: 100% !important;
  }

  .recharts-surface {
    width: 100%;
  }

  tr.RaDatagrid-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 30px;
  }

  td.MuiTableCell-root {
    width: 100%;
    max-width: 50%;
    flex: 50%;
  }

  .RaDatagrid-tableWrapper>table>.RaDatagrid-tbody>tr.RaDatagrid-row td:last-child {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 100%;
    z-index: 99;
  }

  .RaDatagrid-tbody tr td:nth-child(7) {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }

  .css-1u45vn7 {
    flex-direction: column;
  }

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    border-radius: 0px !important;
  }

  .RaDatagrid-tbody tr td::before {
    content: attr(data-label);
    display: block;
    font-size: 10px;
    color: #909090;
    font-family: OpenSans-Regular;
    margin-bottom: 0px;
    text-align: left;
  }

  .MuiCollapse-wrapper .MuiTable-root tbody tr td:last-child {
    position: static !important;
  } 
  .css-1m9fqvd {
    justify-content: space-between;
  }

  .css-1m9fqvd button {
    font-size: 0px !important;
    text-align: center;
  }

  .css-1m9fqvd~a button {
    width: 100%;
  }

  .css-1d6wzja-MuiButton-startIcon {
    margin-left: 0px;
  }

  .app-option-btn label {
    flex: 1;
  }

  .app-option-btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .app-option-btn label span {
    height: 30px;
    padding: 2px 3px;
    font-size: 9px;
  }

  .app-head-row {
    flex-direction: column;
  }

  .revenue-info-row {
    padding: 0px 8px;
  }

  .app-option-btn label {
    margin-right: 0px;
    padding: 0px 2px;
    box-sizing: border-box;
  }

  .app-option-btn label span {
    min-width: auto;
  }

  .t-count h5 {
    font-size: 13px;
  }

  .t-count h5 small {
    font-size: 9px;
  }

  .qv-block h4 {
    font-size: 9px;
  }

  .ta-no-block h4 {
    font-size: 14px;
  }

  .ta-no-block small {
    font-size: 8px;
  }

  .app-count-table table tbody tr td.light-green-td {
    padding: 5px;
  }

  .app-count-table table tbody tr td {
    padding: 7px;
  }

  .cus-switch-btn span {
    width: 25px;
    height: 16px;
  }

  .cus-switch-btn span::after {
    height: 10px;
    width: 10px;
  }

  .a-top-right-block {
    flex-direction: column;
    width: 100%;
  }

  .cus-search {
    width: 100%;
    margin-bottom: 10px;
  }

  .cus-search input {
    min-width: 100%;
  }

  .group-btn {
    width: 100%;
  }

  .group-btn .blue-btn {
    flex: 1;
  }

  .group-btn .g-line-btn {
    margin-left: 0px;
  }

  .details-card-row{
    flex-direction: column;
    align-items: flex-start;
  }
  .static-d-info{
    width: 100%;
    margin: 5px 0px;
    padding: 10px;
  }
  .css-dvxtzn{
    align-items: flex-start;
  }
} */

/* Yes/No button changes start*/
.inner-block-one label{
  font-size: 14px !important;
  text-transform: capitalize;
}

.selector-item_label {
  height: 36px !important;
  min-width: 128px;
}
.selecotr-item.new-c {
  width: 128px !important;
}
.selecotr-item.new-c:first-child {
  margin-right: 4px !important;
}
.selector-item_radio:checked + .selector-item_label {
  color: #2dc3e8 !important;
}
/* Yes/No button changes end*/

/* start padding overight for select component*/
.application_status_style select{
 padding: .375rem 2.25rem .375rem .75rem !important;
}
/*end padding overight for select component*/

/* style for count start*/
.total_count{
  color: #ffffff;
  background-color: #F9BB04;
  padding: 0 4px;
  border-radius: 50px;
  /* border: 2px solid #fff; */
  font-size: 12px;
  font-family: "OpenSans-Bold";
  margin-left: 4px;
}
 /* style for count end*/


@media (max-width: 768px) {
  .details-card-row {
    flex-wrap: wrap;
  }

  .static-d-info {
    width: 100%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 10px 0px;
  }

  .n-app-graph {
    margin-top: 15px;
  }

  .MuiTableBody-root.datagrid-body.RaDatagrid-tbody {
    display: block;
  }

  thead.MuiTableHead-root {
    display: none;
}
  .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven{
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    padding-right: 30px;
  }
  .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td{
    flex:  50%;
    max-width: 50%;
    padding: 5px;
  }
  
    .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td::before{
    content: attr(data-label);
    display: block;
    font-size: 10px;
    color: #909090;
    text-transform: uppercase;
    /* text-align: center; */
  }
  .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:first-child::before,
  .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(2)::before{
    text-align: left;
  }
  /* .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:first-child,
  .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(2){
    max-width: 100%;
    flex: 100%;
    text-align: left !important;
  } */
  /* .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(3),
  .MuiCollapse-vertical .-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(4),
  .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(5),
  .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(6),
  .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(7){
    max-width: 20%;
    flex: 20%;
  } */
  .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(8),
  .MuiCollapse-vertical .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowEven td:nth-child(9){
    max-width: 50%;
    flex: 50%;
  }
  .MuiTableRow-root.RaDatagrid-row.RaDatagrid-rowOdd {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  .recharts-default-legend{
    margin-top: -20px !important;
  }

  /* .css-1u45vn7, */
  /* .css-1m9fqvd, */
  .app-head-row {
    flex-direction: column;
  }
  .css-1m9fqvd{
    flex-wrap: wrap;
  }
  .css-1m9fqvd button{
    max-width: 50%;
    flex: 50%;
    justify-content: flex-start;
  }
  .MuiTableRow-root{
    /* margin-bottom: 15px; */
    display: block;
  }
  .MuiTableHead-root.css-1nb9q0n-MuiTableHead-root {
    display: none;
  }

 

  .app-count-table thead tr th {
    padding: 5px 5px;
  }
  .app-count-table table tbody tr td{
    padding: 5px;
  }
  .ta-no-block{
    line-height: 11px;
  }
  .ta-no-block h4{
    font-size: 15px;
  }
  .ta-no-block small{
    font-size: 9px;
  }
  .n-app-graph{
    width: 100%;
    overflow-x: hidden;
  }
  .recharts-wrapper,
  .recharts-responsive-container,
  .recharts-responsive-container svg{
    width: 100% !important;
  }
  .MuiButtonBase-root.MuiButton-root.responsive_size{
    font-size: 10px !important;
    padding: 2px 5px !important;
  }
  .MuiTableCell-root::before{
    content: attr(data-label);
    display: block;
    font-size: 10px;
    color: #909090;
    font-family: OpenSans-Regular;
    text-transform: uppercase;
  }
  .cus-search input{
    font-size: 10px;
  }
}
@media (max-width: 576px){
  .t-count h5{
    font-size: 14px;
  }
  .t-count h5 small{
    font-size: 9px;
  }
  .css-5btglu{
    margin-top: 10px; 
  }
  .app-option-btn {
    display: flex;
    /* flex-direction: column; */
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 15px;
  }
  .app-option-btn label{
    flex: auto;
    margin-top: 10px; 
  }
  .app-option-btn label span{
    padding: 2px 4px;
    font-size: 9px;
    height: 35px;
  }
  .a-top-right-block{
    width: 100%;
    flex-wrap: wrap;
  }
  .cus-search{
    flex: 1;
    margin-bottom: 15px;
  }
  .cus-search input{
    min-width: 100%;
  }
  .a-top-right-block .group-btn{
    flex: 1;
  }
  .a-top-right-block .group-btn .blue-btn{
    flex: 1;
  }
  .group-btn button:first-child{
    margin-left: 0px;
  }
  .upload-preview {
    display: grid;
  }
}

/* style for LogReportsGraph start*/
.active_users_count h5 {
  font-size: 24px;
  color: #303030;
  font-family: "OpenSans-Bold";
  display: flex;
  align-items: center;
}
.active_users_count h5 small {
  font-size: 12px;
  color: #909090;
  font-family: 'OpenSans-Regular';
  margin-left: 10px;
}
.active_users_count .location_users{
  margin-left: 7px;
  font-size: 12px;
  color: #2DC3E8;
  font-family: 'OpenSans-Bold';
  text-decoration: underline;
}

.toggle_switch .form-switch .form-check-input{
  width: 48px;
  height: 26px;
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232DC3E8'/%3e%3c/svg%3e"); */
  background-color: #EEEEEE;
  border: 1px solid #E0E0E0;
}

.form-switch .form-check-input{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232DC3E8'/%3e%3c/svg%3e");  
}

.toggle_switch .form-check .form-check-input{
  float: none;
}

.toggle_switch .form-check-input:focus {
     border-color: none;
    outline: 0;
    box-shadow:none;
}

.toggle_switch .form-check-input:checked {
  background-color: #2DC3E8;
  border-color: #2DC3E8;
}
.toggle_switch .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232DC3E8'/%3e%3c/svg%3e");
}
.toggle_switch .form-check-input:checked:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.toggle_switch .form-check-input:focus{
  border-color: none;
}
.toggle_switch .form-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle_switch .form-switch label.left{  
  margin-right:41px;
}
.toggle_switch .form-switch label.right{
  margin-left:6px;
}

.toggle_switch .form-switch label.left,
.toggle_switch .form-switch label.right{ 
  font-size: 12px;
  color: #909090;
  font-family: 'OpenSans-Regular';
}

.toggle_switch.is_checked .form-switch label.right{
  color: #2DC3E8;
  font-family: 'OpenSans-Bold';
}

.toggle_switch.is_checked .form-switch label.left{
  color: #909090;
  font-family: 'OpenSans-Bold';
}

.toggle_switch .form-switch label.left{
  color: #2DC3E8;
}

.tracking{
  font-size: 12px;
  color: #303030;
  font-family: 'OpenSans-Bold';
}

/* style for LogReportsGraph end*/


/*docunent view start  */
.well.with-header {
  padding-top: 60px;
  margin-top: 10px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}
.well {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  border: 0;
  color: #262626;
  position: relative;
  margin-bottom: 30px;
  min-height: 0;
}
.well .header[class*=bordered-] {
  border-bottom: 3px solid #fff;
}
.well .header {
  top: 0;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #e5e5e5;
}
.header {
  position: absolute;
  right: 0;
  padding: 10px;
  left: 0;
}
.bordered-themeprimary {
  border-color: #2dc3e8!important;
}
.documentHeader {
  font-weight: normal !important;
  font-size: large;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
.modal-sm-button button span {
 
  line-height: 19px;
 
  font-size: 12px;
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
  margin-left: 3px;
  margin-top: 0;
  
}
.modal-sm-button button {
  
  border: 0;
  background-color: transparent;
  font-size: 12px;
  line-height: 30px;
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
  /* display: flex;
  align-items: center;  */
  margin-top: 10px; /* Adjust the margin as needed */
  padding: 10px;

}
@media (max-width: 768px) {
  .documentHeader {
    font-weight: normal !important;
    font-size: small;
  }
}
/* document view end */
/* adding padding for table
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0 .MuiBox-root
{
  padding: 50px;
} */

/* Application Registration start*/
.register-wrapper {
  background: url("../images/login_bg.jpg") 98% 50%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0px;
  flex-direction: column;
}

.register-wrapper .register-page {
  flex: 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.register-block {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 20px 20px;
}

.register-block .register-header p{
  font-size: 16px;
}

.register-block .register-header h6{
  font-size: 14px;
}
/* Application Registration end*/

/*ResetPassword  progress bar and msg start*/
.pass-progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.pass-progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.pass-progress {
  height: 9px;
  background: #e9e9e9;
  border-radius: 50px;
}

.pass-progress .pass-progress-bar {
  background: #ff8585;
  border-radius: 50px;
}
.pass-msg-box {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 4.86875px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  margin-top: 6px;
}

.pass-msg-box h5 {
  font-size: 10px;
  color: #606060;
  font-family: OpenSans-Regular;
  margin: 0px;
}

.pass-msg-box h5 span {
  font-size: 12px;
  color: #ff8585;
}
/*ResetPassword  progress bar and msg end*/
.text_editor{
  max-width: 1200px;
}
/* style for text editor */
.text_editor .quill .ql-container .ql-editor{
  min-height: 100px;
}
/* style for text editor */

/* style for subsubscriberTemplateDetails */
.remove_mr .form-label {
  margin-bottom: 0 !important;
}

.toggle-heads.change_color button{
  color: rgba(0, 0, 0, 0.54)
}
/* style for subsubscriberTemplateDetails */

/* style for multi email input */
.emp-inputs.multi_email span{
  font-size: inherit;
}
.emp-inputs.multi_email .cus-control{
  height: auto;
  padding: 2px 33px 2px 8px;
}
.emp-inputs.multi_email .react-multi-email > span[data-placeholder]{
  top: auto;
  left:3px;
}

.emp-inputs.multi_email .cus-input-control.is-invalid:focus-within{
  box-shadow: 0 0 0 .25rem rgba(220,53,69,.25);
}

.emp-inputs.multi_email .react-multi-email input{
  background: transparent;
}
.emp-inputs.multi_email .react-multi-email [data-tag]{
  line-height: normal;
}
/*/ style for multi email input */

/* CustomRadioButton res style apply */
.resp_buttons .i-a-block .d-flex > div {
  width: 128px;
}
.resp_buttons .i-a-block .d-flex > div label.btn1{
  min-width: auto;
}
.resp_buttons .i-a-block .d-flex > div:first-child label{
  margin-left: 0 !important;
}
/* CustomRadioButton res style apply */
/*property list style*/
.MuiTableRow-root.MuiTableRow-hover:hover .show_on_hover{
  display: block;
}

.MuiTableRow-root.MuiTableRow-hover .show_on_hover{
  display: none;
}
/* dropdown hover */
.dropdown_dropup .dropdown-menu.show:after{
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #ffffff 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  position: absolute;
  width: 0;
  transform: translateX(-50%);
  top: inherit;
  right: 0;
  margin-left: 0;
}

.dropdown_dropup .dropdown-menu li .dropdown-item:focus, 
.dropdown_dropup .dropdown-menu li .dropdown-item:hover {
  font-family: "OpenSans-Bold";
  color: #2dc3e8;
  background: #ecfbff;
  font-weight: 600;
}

.dropdown_dropup .dropdown-menu li .dropdown-item{
  list-style: none;
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Regular";
  padding: 10px 20px;
  color: #909090;
  font-style: normal;
}

.dropdown_dropup .dropdown-menu{
  min-width: auto;
}
/*property list style*/
/*password style*/
.password_wrapper{
  position: relative;
}

.password_toggle_button {
  position: absolute;
  top: 70%;
  right: 5px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}
/*password style*/

/* adduserforadmin css */
#main-content {
  position: relative;
}
.blue-bg-bx {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f5fdff;
  border-radius: 8px;
  padding: 6px;
}

