/* /* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("./assets/fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold.ttf";
  src: url("./assets/fonts/OpenSans-SemiBold.ttf");
}


@font-face {
  font-family: "OpenSans-Bold";
  src: url("./assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main-content {

  /* background-color: #eceff1; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.mr-l10 {
  margin-left: 10px;
}

.mr-l30 {
  margin-left: 30px;
}

.wrapper {
  position: relative;
  width: 20px;
  height: 70px;
  margin: 10px;
}

.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  margin-top: -12px;
  z-index: 2;
}
.invalid-feedback ::first-letter {
  text-transform: uppercase;
}
.word {
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 12px arial, sans-serif;
  background: #f5fdff;
}



/* Target the scrollbar itself */
::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

/* Target the thumb (draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}


th.MuiTableCell-root.MuiTableCell-head,
th.MuiTableCell-root.MuiTableCell-head span {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  text-transform: uppercase;
  color:#303030;
  font-size: 11px;
 
}

td.MuiTableCell-root.MuiTableCell-body p.MuiTypography-root.MuiTypography-body1 {
  font-family: "OpenSans-Bold" !important;
  font-weight: normal !important;
  font-size: 12px !important;
}

td.MuiTableCell-root.MuiTableCell-body span.MuiTypography-root.MuiTypography-body2 {
  font-family: "OpenSans-Regular" !important;
  font-weight: normal !important;
  color: #303030 !important;
}

.arrow_down_icon path {
  fill: #E7E5E5;
}

.Mui-checked svg.arrow_down_icon path {
  fill: #2DC3E8;
}

.RaDatagrid-tbody tr td:last-child {
  /* text-align: center; */
  position: relative;
}

.RaDatagrid-tbody tr td:last-child button.arrow_icon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #EEEEEE;
  border-radius: 0px;
}

.RaDatagrid-tbody tr td:last-child .arrow_down_icon path {
  fill: #2DC3E8;
}



svg.MuiSvgIcon-root.arrow_up_icon {
  background: #2DC3E8;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  color: #ffffff !important;
}

.MuiSvgIcon-root[data-testid="KeyboardArrowDownIcon"] path {
  transform: scale(0.9) translate(2px, 5px);
}

.MuiSvgIcon-root[data-testid="KeyboardArrowUpIcon"] path {
  transform: scale(0.5) translate(13px, 15px);
}


.css-1olxuey-MuiButtonBase-root-MuiButton-root {
  font-family: "OpenSans-Bold";
}

.MuiButton-text {
  font-weight: normal !important;
  font-family: "OpenSans-Bold" !important;
  font-size: 12px !important;
}

/* 
  .cus-modal-container{
    width: 964px;
    height: auto;
  } */

.cus-modal-container .dialog_title_new_app {
  padding: 30px 30px 20px;
  color: #303030;
  font-family: "OpenSans-SemiBold";
  font-size: 18px;
}

.cus-modal-container .dialog_content_new_app {
  padding: 30px 30px;
}


.app-option-btn {
  flex: 1;

}
.app-option-btn-dashboardQueue {
  flex: 1;
 display: flex; 
} 
.app-option-btn-dashboardQueue label {
  cursor: pointer;
  margin-right: 4px;
 }

.app-option-btn-dashboardQueue label input {
  display: none;
}

.app-option-btn-dashboardQueue label span {
  width: auto;
  height: 40px;
  min-width: 120px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 2px 15px;
  font-size: 14px;
  color: #909090;
  font-family: OpenSans-Regular;
  display: flex;
   white-space: nowrap; 
 
}

.app-option-btn-dashboardQueue label input:checked~span {
  border: 1px solid #2DC3E8;
  background: #ECFBFF;
  color: #2DC3E8;
  font-family: OpenSans-Bold;
}



.app-option-btn label {
  cursor: pointer;
  margin-right: 4px;
 }

.app-option-btn label input {
  display: none;
}

.app-option-btn label span {
  width: auto;
  height: 40px;
  min-width: 120px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 2px 15px;
  font-size: 14px;
  color: #909090;
  font-family: OpenSans-Regular;
  display: flex; 

 
}

.app-option-btn label input:checked~span {
  border: 1px solid #2DC3E8;
  background: #ECFBFF;
  color: #2DC3E8;
  font-family: OpenSans-Bold;
}

.g-line-btn {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.lable-title {
  font-size: 12px;
  color: #606060;
  font-family: "OpenSans-SemiBold";
  margin-bottom: 6px;
}

.color-red {
  color: rgba(255, 72, 72, 1);
}

.app-list-block {
  display: flex;
  flex-direction: column;
}

.cus-check {
  font-size: 12px;
  color: #606060;
  font-family: "OpenSans-Regular";
}

.cus-check input {
  display: none;
}

.cus-check span {
  width: 16px;
  height: 16px;
  border: 1px solid #909090;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.cus-check input:checked~span {
  border: 1px solid #2DC3E8;
  background-color: #2DC3E8;
}

.mr-r10 {
  margin-right: 10px;
}

/* .app-list-block .cus-check{
  margin-bottom: 8px;
} */
.app-list-block {
  margin-top: 29px;
}

.mr-b3 {
  margin-bottom: 3px;
}

.cus-check input:checked~span::before {
  content: "L";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scaleX(-1) rotate(-47deg);
  font-size: 10px;
  color: #ffffff;
  font-family: "OpenSans-Regular";
  line-height: 0;
  margin-top: -1px;
}

.mr-b30 {
  margin-bottom: 30px;
}

.cus-control {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  height: 36px;
  font-size: 14px;
  color: #606060;
  font-family: "OpenSans-Regular";
}

select.cus-control {
  background: url("assets/images/select-arrow.png") no-repeat 95% 50%;
}

.form-control:focus {
  box-shadow: none;
}

.app-g-form {
  background: #F8F8F8;
  border-radius: 8px;
  padding: 20px;
  position:relative;
}

.coun-input {
  position: relative;
}

.data-rates-note {
  font-size: 12px;
  color: #606060;
  font-family: "OpenSans-Regular";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 100%;
  padding-left: 10px;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-title h4 {
  font-size: 14px;
  color: #606060;
  font-family: "OpenSans-Bold";
}

.card-title button {
  background: transparent;
  border: 0px;
  padding: 0px;
  font-size: 12px;
  color: #2DC3E8;
  font-family: "OpenSans-Bold";
}

.card-title button svg {
  margin-right: 4px;
}

.blue-line-btn {
  border: 1px solid #2DC3E8;
  border-radius: 4px;
  height: 40px;
  padding: 5px;
  font-size: 14px;
  font-family: "OpenSans-Bold";
  color: #2DC3E8;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.blue-btn ,.toggle-heads .blue-btn {
  background: #2DC3E8;
  border: 1px solid #2DC3E8;
  box-shadow: 4px 8px 20px rgba(45, 195, 232, 0.2);
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #FFFFFF;
  font-family: "OpenSans-Bold";
}

.blue-btn[disabled] ,.toggle-heads .blue-btn[disabled] {
  background: #D3D3D3; /* Change background color for disabled state */
  border: 1px solid #D3D3D3; /* Change border color for disabled state */
  box-shadow: none; /* Remove box shadow for disabled state */
  color: #A9A9A9; /* Change text color for disabled state */
  cursor: not-allowed; /* Change cursor for disabled state */
}

.g-btm-btn button {
  padding: 0px 30px;
}
.g-btm-btn1 button {
  padding: 0px 20px;
}
.mr-t70 {
  margin-top: 70px;
}

.mr-t20 {
  margin-top: 20px;
}

.mr-r20 {
  margin-right: 20px;
}

.filter-modal .css-dbwlmk-MuiTypography-root-MuiDialogTitle-root {
  padding: 30px;
  border-bottom: 1px solid #ECECEC;
  font-size: 18px;
  color: #303030;
  font-family: "OpenSans-Regular";
}

.filter-modal .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding: 30px 30px;
}

.card-s-title h6 {
  font-size: 12px;
  color: #303030;
  font-family: "OpenSans-Bold";
}

.date-label span {
  font-size: 14px;
  color: #303030;
  font-family: "OpenSans-Regular";
  margin-right: 10px;
}

.date-label span strong {
  font-weight: normal;
}

.app-list .css-pod7ec-MuiFormControlLabel-root-RaCheckboxGroupInputItem-root .MuiFormControlLabel-label {
  font-size: 12px;
  color: #000000;
  font-family: "OpenSans-Regular";
}

.css-1cmj83y-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
  padding-left: 0px !important;
}


.details-card-row {
  display: flex;
  padding: 20px;
  background: #F9F9F9;
   border: 1px solid #E0E0E0;
}

.static-d-info {
  flex: auto;
  align-items: center; /* Center align vertically within flex container */
  margin-bottom: 10px;
}


.static-d-info label {
  font-size: 12px;
  color: #909090;
  font-family: "OpenSans-Regular";
  white-space: nowrap;
  user-select: none; 
  width: auto;
}

.static-d-info h5 {
  margin: 0;
  font-size: 12px;
  color: #303030;
  font-family: "OpenSans-Regular";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 20px; 
  user-select: none; 
}

.static-d-info button.sub-btn {
  background: #B0EFFF;
  border-radius: 4px;
  height: 32px;
  font-size: 12px;
  color: #127791;
  font-family: "OpenSans-SemiBold";
  border: 1px solid #B0EFFF;
  padding: 5px 10px;
  }

.static-d-info a {
  font-size: 12px;
  color: #2DC3E8;
  font-family: "OpenSans-Bold";
  text-decoration: none;
}

.trans-btn {
  padding: 0px;
  border: 0px;
  background-color: transparent;
}

.mr-l4 {
  margin-left: 4px;
}

@media (max-width: 768px) {

  .MuiTableRow-root{
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .MuiTableRow-root .MuiTableCell-root,
  .MuiTableCell-root {
    width: 100%;
    /* max-width: 50%; */
    flex: 50%;
  }

  .RaDatagrid-root .RaDatagrid-row {
    position: relative;
    padding-right: 30px;
  }

  /* Note : Avi : remove the code causing issue for copy url table reponsive */


  .RaDatagrid-rowEven .MuiTableCell-paddingNone,
  .RaDatagrid-rowOdd .MuiTableCell-paddingNone {
    position: absolute !important;
    width: 30px !important;
    right: 0px;
    height: 100%;
  }
  
  #copyurl .RaDatagrid-rowEven .MuiTableCell-paddingNone,
  #copyurl .RaDatagrid-rowOdd .MuiTableCell-paddingNone {
     position: relative !important;
     width: auto !important;
     height: 70px !important;
     text-align: left
  }

  #copyurl .RaDatagrid-rowEven .MuiTableCell-paddingNone button,
  #copyurl .RaDatagrid-rowOdd .MuiTableCell-paddingNone button{
    width: 100%;
  }

  .css-1rkx2ov-RaDatagrid-root .RaDatagrid-row {
    background-color: #ffffff !important;
  }

  .css-1rhqant-MuiTableBody-root .RaDatagrid-rowEven {
    background-color: #F9F9F9 !important;
  }

  .app-option-btn label span {
    min-width: auto;
  }

  .app-option-btn-dashboardQueue label span {
    min-width: auto;
  }
  .total_count {
    font-size: 8px !important;
  }

  .RaDatagrid-root .RaDatagrid-row td:nth-child(odd) {
    position: relative;
  }
  .RaDatagrid-rowEven td{
    position: relative;
  }
  .RaDatagrid-root .RaDatagrid-row td:nth-child(odd)::after,
  .RaDatagrid-rowEven td:nth-child(odd)::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    background-color: #dfdfdf;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    /* border-right: 1px solid #dfdfdf; */
  }
  .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiPaper-root .MuiBox-root .RaDatagrid-root .RaDatagrid-tableWrapper .MuiTable-root tbody tr{
    padding-right: 0px !important;
  }
  .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiPaper-root .MuiBox-root .RaDatagrid-root .RaDatagrid-tableWrapper .MuiTable-root tbody tr td{
    position: static !important;
    height: 80px;
  }
  .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiPaper-root .MuiBox-root .RaDatagrid-root .RaDatagrid-tableWrapper .MuiTable-root tbody tr td::before{
    text-align: center !important;
  }
  .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiPaper-root .MuiBox-root .RaDatagrid-root .RaDatagrid-tableWrapper .MuiTable-root tbody tr td:nth-child(1)::before,
  .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiPaper-root .MuiBox-root .RaDatagrid-root .RaDatagrid-tableWrapper .MuiTable-root tbody tr td:nth-child(2)::before{
    text-align: left !important;
  }

  .css-5btglu {
    padding: 0px !important;
    margin: 0px !important;
  }

  .css-1u45vn7 {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .RaDatagrid-tbody tr td:last-child {
    display: block;
    width: 100%;
  }

  .css-10ze0lv-MuiTableRow-root {
    display: block;
    width: 100%;
  }
}

/* Add this to your CSS file */
#google_translate_element {
  margin: 10px 0;
}

.goog-te-combo {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  top: auto !important;
  /* position: relative !important; */
  /* display: none !important; */
  bottom: 0px !important;
}

#google_translate_element{
  overflow: hidden;
  width: 140px;
}
.switchsubscriber{
  font-size: 12px;
  font-weight: 600;
}
/* Add this CSS to your project's stylesheet */
.custom-toast {
  background-color: #5cb85c !important; /* Similar green color */
  color: white !important;
  font-size: 16px;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
