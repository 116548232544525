.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* temp delete later */
.css-1kqc2bv,
.css-ujxmah-MuiPaper-root-MuiAppBar-root-RaAppBar-root {
  background: #2cc0e4 !important;
}

.css-1u638ht-RaLayout-root .RaLayout-appFrame,
.css-12xfrtr .RaLayout-appFrame {
  margin-top: 0px !important;
}

@media (min-width:0) {
  .css-12xfrtr .RaLayout-appFrame {
    margin-top: 0px !important;
  }
}

.MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked .MuiPaper-root:has(.MuiList-root.MuiList-padding.RaMenu-open) {
  width: 293px !important;
}

.MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked .RaSidebar-fixed {
  padding: 0px 10px;
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters{
  text-wrap: pretty;
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.RaMenuItemLink-active {
  font-size: 12px !important;
  color: #909090 !important;
  font-family: OpenSans-Regular !important;
  height: 40px;
  /* width: 270px; */
  text-wrap: pretty;
  box-sizing: border-box;
  margin-bottom: 10px !important;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.RaMenuItemLink-active.RaMenuItemLink-active {
  font-family: OpenSans-Bold !important;
  color: #2DC3E8 !important;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.RaMenuItemLink-active.RaMenuItemLink-active svg path {
  fill: #2DC3E8;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.RaMenuItemLink-active.RaMenuItemLink-active.RaMenuItemLink-active::before {
  content: "";
  height: 24px;
  width: 4px;
  background: #2DC3E8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.badge-cell {
  position: relative;
  display: grid;
  align-items: center;
}

.badge-cell em {
  /* position: absolute; */
  top: -8px;
  right: 5px;
  width: 21px;
  height: 10px;
  align-items: center;
  justify-content: center;
  font-size: 6px;
  color: #FFF !important;
  border-radius: 2px;
  background: #01DB3E;
  padding: 0px;
  text-decoration: none;
  font-style: normal;
  display: inline-flex;
  margin-left: 5px;
}
/* Basic styling for the note editor */
.note-editor {
  display: flex;
  flex-direction: column;
 
}

.textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
  height: 234px;
}


/* Gray color for inactive links */
.nav-tabs .nav-link {
  color: gray;
}

/* Black color for active link */
.nav-tabs .nav-link.active {
  color: black;
}


.add-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #2dc3e8;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button:hover {
  background-color: #2dc3e8;
}
/* .close-icon{
  color:gray;
  margin-top: 10px;
  margin-left: 400px;
  display: flex;
} */
.required {
  color: red;
  font-weight: bold;
  margin-left: 5px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.button-group button {
  margin-bottom: 20px;
}

